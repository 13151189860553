<template>
  <transition
    enter-class="opacity-0"
    enter-active-class="ease-out transition-medium"
    enter-to-class="opacity-100"
    leave-class="opacity-100"
    leave-active-class="ease-out transition-medium"
    leave-to-class="opacity-0"
  >
    <div
      @keydown.esc="isOpen = false"
      v-show="showBackdrop"
      class="fixed inset-0 transition-opacity"
    >
      <div
        @click="isOpen = false"
        class="absolute inset-0 bg-black opacity-50"
        tabindex="0"
      ></div>
    </div>
  </transition>

  <div
    v-if="showNewTeacherModal"
    class="
      modal
      shadow-lg
      rounded-2xl
      p-4
      bg-white
      h-3/5
      w-full
      md:w-4/5
      lg:w-2/5
      m-auto
      z-30
      transform
      top-0
      left-0
      bg-white
    "
  >
    <div class="w-full h-full text-center">
      <div class="flex h-full flex-col justify-between">
        <div
          class="
            self-center
            mt-2
            mb-2
            text-xl
            font-light
            text-gray-800
            sm:text-2xl
            dark:text-white
          "
        >
          Novo professor
        </div>
        <div class="p-2 mt-2">
          <form>
            <div class="flex flex-col mb-2">
              <div class="relative text-left">
                <input
                  type="text"
                  required
                  :disabled="creatingNewTeacher"
                  id="create-account-name"
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-full
                    py-2
                    px-4
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-base
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  name="name"
                  v-model="name"
                  placeholder="Nome"
                  @blur="validateInput('name')"
                />
                <small v-if="errors.name" id="nameError" class="text-red-500">
                  {{ errors.name }}
                </small>
              </div>
            </div>
            <div class="flex flex-col mb-2">
              <div class="relative text-left">
                <input
                  type="text"
                  required
                  :disabled="creatingNewTeacher"
                  id="create-account-email"
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-full
                    py-2
                    px-4
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-base
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  name="email"
                  v-model="email"
                  placeholder="Email"
                  @blur="validateInput('email')"
                />
                <small v-if="errors.email" id="emailError" class="text-red-500">
                  {{ errors.email }}
                </small>
              </div>
            </div>
            <div class="flex flex-col mb-2">
              <div class="relative text-left">
                <input
                  type="text"
                  required
                  :disabled="creatingNewTeacher"
                  id="create-account-password"
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-full
                    py-2
                    px-4
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-base
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  name="password"
                  v-model="password"
                  placeholder="Senha"
                  @blur="validateInput('password')"
                />
                <small
                  v-if="errors.password"
                  id="passwordError"
                  class="text-red-500"
                >
                  {{ errors.password }}
                </small>
              </div>
            </div>
          </form>
        </div>

        <div class="flex items-center justify-between gap-4 w-full mt-4">
          <button
            type="button"
            :disabled="
              this.errors.name ||
              this.errors.email ||
              this.errors.password ||
              creatingNewTeacher
            "
            @click="onNewTeacher"
            class="
              py-2
              px-4
              bg-indigo-600
              hover:bg-indigo-700
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
              disabled:opacity-10
            "
          >
            <svg
              v-if="creatingNewTeacher"
              class="
                relative
                left-1/2
                animate-spin
                -ml-1
                mr-3
                h-5
                w-5
                text-white
              "
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <span v-if="!creatingNewTeacher">Criar</span>
          </button>
          <button
            type="button"
            @click="onCloseNewTeacherModal"
            class="
              py-2
              px-4
              bg-white
              hover:bg-gray-100
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-indigo-500 text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
            "
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="
      w-100
      mx-auto
      bg-white
      border-t-4
      rounded
      border-indigo-500
      dark:bg-gray-800
      shadow-md
      font-sans
      text-center
      p-4
    "
  >
    <div class="overflow-hidden">
      <div class="flex flex-wrap item-center justify-between m-2 mb-6">
        <h1 class="text-2xl text-left font-medium text-gray-800">
          Professores
        </h1>
        <button
          @click="onShowNewTeacherModal"
          class="
            px-3
            py-2
            bg-gray-800
            text-white text-xs
            font-bold
            uppercase
            rounded
            bg-indigo-600
            hover:bg-indigo-700
            focus:ring-indigo-500 focus:ring-offset-indigo-200
            transition
            ease-in
            duration-200
            font-semibold
            shadow-md
            focus:outline-none focus:ring-2 focus:ring-offset-2
          "
        >
          Novo professor
        </button>
      </div>

      <TeachersTable
        :teachers="teachers"
        :loading="loadingTeachers"
        :updatingStatus="updatingStatus"
        :updateTeacherStatus="updateTeacherStatus"
      />
    </div>
  </div>

  <div
    v-if="showErrorNewTeacherModal"
    class="
      modal
      shadow-lg
      rounded-2xl
      p-4
      bg-white
      dark:bg-gray-800
      w-64
      m-auto
      z-30
      transform
      top-0
      left-0
    "
  >
    <div class="w-full h-full text-center">
      <div class="flex h-full flex-col justify-between">
        <svg viewBox="0 0 24 24" class="h-12 w-12 mt-4 m-auto text-red-600">
          <path
            fill="currentColor"
            d="M11.983,0a12.206,12.206,0,0,0-8.51,3.653A11.8,11.8,0,0,0,0,12.207,11.779,11.779,0,0,0,11.8,24h.214A12.111,12.111,0,0,0,24,11.791h0A11.766,11.766,0,0,0,11.983,0ZM10.5,16.542a1.476,1.476,0,0,1,1.449-1.53h.027a1.527,1.527,0,0,1,1.523,1.47,1.475,1.475,0,0,1-1.449,1.53h-.027A1.529,1.529,0,0,1,10.5,16.542ZM11,12.5v-6a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Z"
          ></path>
        </svg>
        <p class="text-gray-600 dark:text-gray-100 text-md py-2 px-6">
          Erro ao cadastrar o email
          <span class="text-gray-800 dark:text-white font-bold">
            {{ this.email }}
          </span>
          {{ errors.auth ? errors.auth : null }}
        </p>
        <div class="flex items-center justify-between gap-4 w-full mt-8">
          <button
            type="button"
            @click="onHideErrorNewTeacherModal"
            class="
              py-2
              px-4
              bg-indigo-600
              hover:bg-indigo-700
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
            "
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="showSuccessNewTeacherModal"
    class="
      modal
      shadow-lg
      rounded-2xl
      p-4
      bg-white
      dark:bg-gray-800
      w-64
      m-auto
      z-30
      transform
      top-0
      left-0
    "
  >
    <div class="w-full h-full text-center">
      <div class="flex h-full flex-col justify-between">
        <svg
          class="h-12 w-12 mt-4 m-auto text-green-500"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 13l4 4L19 7"
          ></path>
        </svg>
        <p class="text-gray-600 dark:text-gray-100 text-md py-2 px-6">
          Usuário de email
          <span class="text-gray-800 dark:text-white font-bold">
            {{ this.email }}
          </span>
          criado com sucesso!
        </p>
        <div class="flex items-center justify-between gap-4 w-full mt-8">
          <button
            type="button"
            @click="onHideSuccessNewTeacherModal"
            class="
              py-2
              px-4
              bg-indigo-600
              hover:bg-indigo-700
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
            "
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeachersTable from "@/components/TeachersTable";
import { ref, reactive } from "vue";
import Firebase from "@/firebase";
import Swal from "sweetalert2";
const axios = require("axios").default;

export default {
  components: {
    TeachersTable,
  },

  setup() {
    const teachers = ref([]);
    const loadingTeachers = ref(true);
    // const auth = Firebase.getAuth();

    const getTeachers = async () => {
      loadingTeachers.value = true;
      const teachersSnapshots = await Firebase.getDocs("professores");
      teachers.value = teachersSnapshots.docs.map((snapshot) =>
        snapshot.data()
      );
      loadingTeachers.value = false;
    };

    const errors = reactive({
      name: null,
      email: null,
      password: null,
      auth: null,
    });

    const updatingStatus = ref(false);
    const updateTeacherStatus = async (teacher) => {
      updatingStatus.value = true;
      const teacherPath = `${Firebase.collections.TEACHERS}/${teacher.uid}`;
      try {
        await Firebase.setDoc(teacherPath, { ativo: !teacher.ativo });
        // auth.updateUser(teacher.uid, { disabled: teacher.ativo });
        getTeachers();
      } catch (e) {
        console.error(e);
        Swal.fire({
          icon: "error",
          title: "Opa...",
          text: "Não foi possível alterar o status do professor!",
          footer: "Caso o erro persista, contate o administrador.",
        });
      } finally {
        updatingStatus.value = false;
      }
    };

    return {
      loadingTeachers,
      teachers,
      getTeachers,
      errors,
      updatingStatus,
      updateTeacherStatus,
    };
  },

  data() {
    return {
      showBackdrop: false,
      creatingNewTeacher: false,
      showSuccessNewTeacherModal: false,
      showErrorNewTeacherModal: false,
      showNewTeacherModal: false,
      name: "",
      email: "",
      password: "",
    };
  },

  methods: {
    onShowNewTeacherModal() {
      this.showNewTeacherModal = true;
      this.showBackdrop = true;
    },
    onHideErrorNewTeacherModal() {
      this.creatingNewTeacher = false;
      this.onCloseNewTeacherModal();
      this.showNewTeacherModal = true;
      this.showBackdrop = true;
      this.showErrorNewTeacherModal = false;
    },
    onHideSuccessNewTeacherModal() {
      this.creatingNewTeacher = false;
      this.onCloseNewTeacherModal();
      this.showNewTeacherModal = true;
      this.showBackdrop = true;
      this.showSuccessNewTeacherModal = false;
    },
    async onNewTeacher() {
      if (
        !this.name ||
        !this.email ||
        !this.password ||
        this.errors.name ||
        this.errors.email ||
        this.errors.password
      ) {
        return;
      }

      this.creatingNewTeacher = true;

      const newTeacher = {
        name: this.name,
        email: this.email,
        password: this.password,
      };

      await axios
        .post(Firebase.API_URL("newTeacher"), newTeacher)
        .then(() => {
          this.getTeachers();

          this.showBackdrop = true;
          this.showNewTeacherModal = false;
          this.showSuccessNewTeacherModal = true;
        })
        .catch((e) => {
          const { message } = e.response.data;
          this.errors.auth = message;

          this.showNewTeacherModal = false;
          this.showErrorNewTeacherModal = true;
        });
    },

    validateInput(formName) {
      if (formName === "email") {
        if (/^\S+@\S+$/.test(this.email)) {
          this.errors.email = false;
        } else {
          this.errors.email = "Email inválido";
        }
      }

      if (formName === "name") {
        if (!this.name || this.name.length < 5) {
          this.errors.name = "Nome inválido. Mínimo 5 caracteres";
        } else {
          this.errors.name = false;
        }
      }

      if (formName === "password") {
        if (!this.password || this.password.length < 8) {
          this.errors.password = "Senha inválida. Mínimo 8 caracteres";
        } else {
          this.errors.password = false;
        }
      }
    },

    onCloseNewTeacherModal() {
      this.showNewTeacherModal = false;
      this.showBackdrop = false;
      this.name = "";
      this.email = "";
      this.password = "";
      this.errors.name = "";
      this.errors.email = "";
      this.errors.password = "";
      this.errors.auth = null;
    },
  },

  mounted() {
    this.getTeachers();
  },
};
</script>

<style scoped>
.modal {
  position: fixed !important;
  height: auto !important;
  top: 100px !important;
  left: 0 !important;
  right: 0 !important;
}
</style>
