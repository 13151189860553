<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  Nome
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-center text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  Status
                </th>
                <!-- <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Gerenciar</span>
                </th> -->
              </tr>
            </thead>

            <tbody v-if="teachers.length === 0 && !loading">
              <tr>
                <td colspan="3" class="px-6 py-8 whitespace-nowrap">
                  Não existem professores cadastrados
                </td>
              </tr>
            </tbody>

            <tbody v-if="loading">
              <tr>
                <td colspan="3" class="px-6 py-8 whitespace-nowrap">
                  <svg
                    class="
                      relative
                      left-1/2
                      animate-spin
                      -ml-1
                      mr-3
                      h-5
                      w-5
                      text-purple-600
                    "
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </td>
              </tr>
            </tbody>

            <tbody v-if="!loading" class="bg-white divide-y divide-gray-200">
              <tr v-for="teacher in teachers" :key="teacher.uid">
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 h-10 w-10">
                      <img
                        class="h-10 w-10 rounded-full"
                        src="@/assets/img/user_placeholder.png"
                        alt=""
                      />
                    </div>
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ teacher.nome }}
                      </div>
                      <div class="text-sm text-gray-500">
                        {{ teacher.email }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <span
                    v-if="!updatingStatus"
                    @click="updateTeacherStatus(teacher)"
                    class="
                      px-2
                      inline-flex
                      text-xs
                      leading-5
                      font-semibold
                      rounded-full
                      cursor-pointer
                    "
                    :class="{
                      'bg-green-100': teacher.ativo,
                      'text-green-800': teacher.ativo,
                      'text-red-800': !teacher.ativo,
                      'bg-red-100': !teacher.ativo,
                    }"
                  >
                    {{ teacher.ativo ? "Ativo" : "Inativo" }}
                  </span>

                  <svg
                    v-if="updatingStatus"
                    class="
                      relative
                      left-1/2
                      animate-spin
                      -ml-1
                      mr-3
                      h-5
                      w-5
                      text-purple-600
                    "
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </td>
                <!-- <td
                  class="
                    px-4
                    py-4
                    whitespace-nowrap
                    text-right text-sm
                    font-medium
                  "
                >
                  <a
                    class="text-indigo-600 hover:text-indigo-900"
                    @click="editTeacher(teacher)"
                    >Gerenciar</a
                  >
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["teachers", "loading", "updatingStatus", "updateTeacherStatus"],

  setup() {
    return {};
  },
};
</script>
